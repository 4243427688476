// State
export const state = () => ({
	promotions: [],
	availablePromotions: [],
	promotion: {},
	availablePromotion: {},
	loading: false,
	activePromotion: {},
	endPromotion: [],
	rollingReward: {},
	histories: {}
})

// Actions
export const actions = {
	// set promotion
	setPromotion({ commit }, data) {
		commit('setPromotion', data)
	},

	// get promotion in promotion page
	async getAvailablePromotions({ commit, rootState }) {
		commit('setLoading', true)
		this.commit('settings/setCancelToken', { promotion: this.$axios.CancelToken.source() })

		await this.$axios
			.get(`/promotions/available/${rootState.settings.defaultCurrency}`, {
				cancelToken: this.state.settings.cancelToken.promotion.token
			})
			.then((response) => {
				commit('setAvailablePromotions', response.data)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
			})
	},
	// get promotion
	async getPromotions({ commit, rootState }) {
		commit('setLoading', true)
		this.commit('settings/setCancelToken', { promotion: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/promotions?currency=${rootState.settings.defaultCurrency}`, {
				cancelToken: this.state.settings.cancelToken.promotion.token
			})
			.then((response) => {
				commit('setPromotions', response.data)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
			})
	},
	async claimPromotion({ commit }, params) {
		commit('setLoading', true)
		const response = await this.$axios
			.post(`/promotions/${params}`)
			.then((response) => {
				commit('setLoading', false)
				return true;
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
		return response;
	},
	async activePromotion({ commit }, params) {
		this.commit('settings/setCancelToken', { activePromotion: this.$axios.CancelToken.source() })
		const response = await this.$axios
			.get(`/promotions/active`, {
				cancelToken: this.state.settings.cancelToken.activePromotion.token
			})
			.then((response) => {
				commit('setActivePromotion', response.data)
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async endPromotion({ commit }, params) {
		this.commit('settings/setCancelToken', { endPromotion: this.$axios.CancelToken.source() })
		const response = await this.$axios
			.get(`/promotions/ended`, {
				cancelToken: this.state.settings.cancelToken.endPromotion.token
			})
			.then((response) => {
				commit('setEndPromotion', response.data)
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async rollingReward({ commit },) {
		this.commit('settings/setCancelToken', { rollingReward: this.$axios.CancelToken.source() })
		const response = await this.$axios
			.get(`/rolling-reward/rolling`, {
				cancelToken: this.state.settings.cancelToken.rollingReward.token
			})
			.then((response) => {
				commit('setRollingReward', response.data)
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async histories({ commit }, params) {
		this.commit('settings/setCancelToken', { promotionHistory: this.$axios.CancelToken.source() })
		const response = await this.$axios
			.get(`/promotions/history`, { params, cancelToken: this.state.settings.cancelToken.promotionHistory.token })
			.then((response) => {
				commit('setHistory', response.data)
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async claimPromotionCode({ commit, dispatch }, code) {
		const uri = `/promotion-codes/claim/${code}`
		const encoded = encodeURI(uri);
		const response = await this.$axios
			.get(encoded)
			.then((response) => {
				dispatch('player/totalBalance', {}, { root: true })
				commit('setHistory', response.data)
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async checkPromotionWalletPopup({ _ }, id) {
		const response = await this.$axios
			.get(`promotions/claim/wallet-popup/${id}`)
			.then((response) => {
				return response.data;
			})
			.catch(() => {
				return false
			})
		return response
	}
}

// Mutations
export const mutations = {
	setPromotions: (state, val) => {
		state.promotions = val
	},
	setAvailablePromotions: (state, val) => {
		state.availablePromotions = val
	},
	setAvailablePromotion: (state, val) => {
		state.availablePromotion = val
	},
	setPromotion: (state, val) => {
		state.promotion = val
	},
	setLoading: (state, val) => {
		state.loading = val
	},
	setActivePromotion: (state, val) => {
		state.activePromotion = val
	},
	setEndPromotion: (state, val) => {
		state.endPromotion = val
	},
	setRollingReward: (state, val) => {
		state.rollingReward = val
	},
	setHistory: (state, val) => {
		state.histories = val
	},
}

// Getters
export const getters = {
	promotions: (state) => {
		return state.promotions.filter(el => el.is_visible === true)
	},
	availablePromotions: (state) => {
		return state.availablePromotions
	},
	availablePromotion: (state) => {
		return state.availablePromotion
	},
	promotion: (state) => {
		return state.promotion
	},
}
